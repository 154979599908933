import { IncludeRelatedSimilar, IRaptor } from './raptor.definitions';
import { handlers } from './raptor-calls';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from '~/shared/utils/translation';
import { useUtils } from '~/shared/hooks/useUtils';
import useCustomer from '~/features/commerce-api/hooks/useCustomer';
import { RaptorDropdownTypes } from '~/lib';
import useBasket from '~/features/commerce-api/hooks/useBasket';
import { useRouter } from 'next/router';
import { Product } from '~/lib/data-contract/salesforce';
import { isValidId, mapRaptorProductData } from './raptor-utils';

const isServer = typeof window === 'undefined';

type Props = {
    param?: string;
    productId?: string;
    productBrand?: string;
    productCategory?: string;
    merchandisingCampaignId?: string;
};

type Result = (props: Props) => Promise<any>;

const useGetRaptorMethod = (method: RaptorDropdownTypes): Result | undefined => {
    const { customerId, c_imercoCustomerNo } = useCustomer();
    const { productItems } = useBasket();
    const cookieId = !isServer && window.raptor?.cookieId ? window.raptor.cookieId : undefined;
    const standardData = { cookieId, userId: c_imercoCustomerNo ?? customerId, amount: 10 };
    switch (method) {
        case 'GetOverAllTopSelling':
            return async (_props: Props) => ({
                products: await handlers.mostPurchasedProducts({ ...standardData }),
            });
        case 'GetCookieRecommendations':
            return async (_props: Props) => ({
                products: await handlers.personalRecommendationsForYouByCookieId({
                    ...standardData,
                }),
            });
        case 'GetTopPurchasedInBrands':
            return async (_props: Props) => ({
                products: await handlers.mostPurchasedProductsInAGivenBrand({
                    ...standardData,
                    brand: _props.productBrand || _props.param,
                }),
            });
        case 'GetTrendingItems':
            return async (_props: Props) => ({
                products: await handlers.getTrendingProducts({ ...standardData }),
            });
        case 'RelatedOrSimilarProductsOthersBought':
            return async (_props: Props) => ({
                products: await handlers.relatedProductOtherCustomersHaveAlsoPurchased({
                    productId: _props.productId,
                    includeRelatedProducts: IncludeRelatedSimilar.Both,
                    ...standardData,
                }),
            });
        case 'GetRelatedItems':
            return async (_props: Props) => ({
                products: await handlers.relatedProductOtherCustomersHaveAlsoPurchased({
                    productId: _props.productId,
                    includeRelatedProducts: IncludeRelatedSimilar.No,
                    ...standardData,
                }),
            });
        case 'RelatedAndSimilarProductsOthersBought':
            return async (_props: Props) => ({
                products: await handlers.relatedProductOtherCustomersHaveAlsoPurchased({
                    productId: _props.productId,
                    includeRelatedProducts: IncludeRelatedSimilar.Yes,
                    ...standardData,
                }),
            });
        case 'GetRelatedItemsForBasket':
            return async (_props: Props) => ({
                products: await handlers.customersWhoPurchasedTheseProductsAlsoBoughtBasket({
                    ...standardData,
                    productIds: productItems?.map((x) => x.productId).filter(isValidId),
                    relatedProducts: productItems?.map((x) => x.productId).filter(isValidId),
                }),
            });
        case 'GetTopSellingInCategory':
            return async (_props: Props) => ({
                products: await handlers.mostPurchasedProductsInAGivenCategory({
                    ...standardData,
                    category: _props.productCategory || _props.param,
                }),
            });
        case 'GetCookieHistory':
            return async (_props: Props) => ({
                products: await handlers.productsYouHaveRecentlySeenByCookieId({
                    ...standardData,
                }),
            });
        case 'GetTrendingBrands':
            return async (_props: Props) => ({
                products: await handlers.getTrendingBrands({ ...standardData }),
            });
        case 'GetSimilarItems':
            return async (_props: Props) => ({
                products: await handlers.similarProductsOtherCustomersHaveAlsoPurchased({
                    ...standardData,
                    productId: _props.productId,
                    includeSimilarProducts: IncludeRelatedSimilar.Yes,
                }),
            });
        case 'GetPopularItemsInCategoryWeb':
            return async (_props: Props) => ({
                products: await handlers.getPopularItemsInCategoryWeb({
                    ...standardData,
                    category: _props.productCategory || _props.param,
                }),
            });
        case 'GetPopularItemsInBrandWeb': {
            return async (_props: Props) => ({
                products: await handlers.getPopularItemsInBrandWeb({
                    ...standardData,
                    brand: _props.productBrand || _props.param,
                }),
            });
        }
        case 'GetUserItemRecommendationsWeb':
            return async (_props: Props) => ({
                products: await handlers.getUserItemRecommendationsWeb({
                    ...standardData,
                }),
            });
        case 'GetMerchandisingItemsWeb':
            return async (_props: Props) => ({
                products: await handlers.getMerchandisingItemsWeb({
                    merchandisingCampaignId: _props.merchandisingCampaignId,
                    ...standardData,
                }),
            });
        case 'GetPopularItemsWeb':
            return async (_props: Props) => ({
                products: await handlers.getPopularItemsWeb({
                    ...standardData,
                }),
            });

        default:
            return undefined;
    }
};

export const useRaptorProducts = ({
    config,
    param,
    productId,
    productBrand,
    productCategory,
    preventAutoFetch = false,
    merchandisingCampaignId,
}: IRaptor) => {
    const { translate } = useTranslation();
    const { convertPriceToLocale } = useUtils();
    const { loaded } = useBasket();
    const { isInitialized } = useCustomer();
    const { asPath } = useRouter();

    const getFn = useGetRaptorMethod(config);
    const isEnabled = !!config && !!getFn && !!isInitialized && !!loaded;

    const {
        data: productData,
        refetch: productRefetch,
        isError: productIsError,
        isSuccess: productIsSuccess,
        isLoading: productIsLoading,
        isFetching: productIsFetching,
        isPending: productIsPending,
    } = useQuery({
        queryKey: [
            config,
            asPath,
            productId,
            productBrand,
            productCategory,
            merchandisingCampaignId,
            param,
        ],
        queryFn: () =>
            getFn?.({
                productId: param || productId,
                productBrand: param || productBrand,
                productCategory: param || productCategory,
                merchandisingCampaignId: param || merchandisingCampaignId,
            }),
        select: (data) => {
            try {
                if (Array.isArray(data?.products)) {
                    return data.products.map((product: Product) =>
                        mapRaptorProductData(product, translate, convertPriceToLocale),
                    );
                }
            } catch (error) {
                console.error({ error });
            }
        },
        staleTime: 5 * 60 * 1000, // Data is fresh for 5 minute
        gcTime: 10 * 60 * 1000, // Data is removed from cache after 10 minutes
        enabled: isEnabled && !preventAutoFetch,
        refetchOnMount: 'always', // we always want the latest because of calls like "Last viewed products"
    });

    const refetch = () => {
        if (isEnabled) {
            productRefetch();
        }
    };

    return {
        data: productData,
        isError: productIsError,
        isSuccess: productIsSuccess,
        isLoading: productIsLoading || productIsFetching || productIsPending,
        refetch,
    };
};
