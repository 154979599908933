import { RaptorMethods, RaptorParams } from './raptor.definitions';

const baseUrl = '/api/raptor/Recommendations/products';

export const getProducts = async (
    query: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params: Record<string, any>,
): Promise<string[]> => {
    try {
        if (!params.amount) {
            params.amount = 10;
        }

        const p = new URLSearchParams();

        Object.keys(params).forEach((key) => {
            const value = params[key];

            if (Array.isArray(value)) {
                if (key === 'productIds') {
                    p.append('productIds', value.join(','));
                } else {
                    value.forEach((item: any) => {
                        p.append(key, item);
                    });
                }
            } else {
                p.append(key, value);
            }
        });

        const url = `${baseUrl}/${query}?${p.toString()}`;

        const t = await fetch(url);
        const data = await t.json();

        return data;
    } catch (e) {
        throw new Error(e as string);
    }
};

export const mostPurchasedProducts = async ({
    cookieId,
    amount,
}: RaptorParams): Promise<string[]> => {
    return await getProducts(RaptorMethods['mostPurchasedProducts'], { cookieId, amount });
};

export const mostPurchasedProductsInAGivenCategory = async ({
    category,
    cookieId,
    amount,
}: RaptorParams): Promise<string[]> => {
    return await getProducts(RaptorMethods['mostPurchasedProductsInAGivenCategory'], {
        cookieId,
        amount,
        category,
    });
};

export const similarProductsOtherCustomersHaveAlsoPurchased = async ({
    productId,
    cookieId,
    amount,
    includeSimilarProducts,
}: RaptorParams): Promise<string[]> => {
    return await getProducts(RaptorMethods['similarProductsOtherCustomersHaveAlsoPurchased'], {
        cookieId,
        amount,
        includeSimilarProducts,
        productId,
    });
};

export const relatedProductOtherCustomersHaveAlsoPurchased = async ({
    productId,
    cookieId,
    amount,
    includeRelatedProducts,
}: RaptorParams): Promise<string[]> => {
    return await getProducts(RaptorMethods['relatedProductOtherCustomersHaveAlsoPurchased'], {
        cookieId,
        amount,
        includeRelatedProducts,
        productId,
    });
};

export const mostVisitedProducts = async ({
    cookieId,
    amount,
}: RaptorParams): Promise<string[]> => {
    return await getProducts(RaptorMethods['mostVisitedProducts'], {
        cookieId,
        amount,
    });
};

export const mostViewedProductsInAGivenCategory = async ({
    category,
    cookieId,
    amount,
}: RaptorParams): Promise<string[]> => {
    return await getProducts(RaptorMethods['mostViewedProductsInAGivenCategory'], {
        cookieId,
        category,
        amount,
    });
};

export const customersWhoPurchasedTheseProductsAlsoBoughtBasket = async ({
    productIds,
    cookieId,
    amount,
}: RaptorParams): Promise<string[]> => {
    return await getProducts(RaptorMethods['customersWhoPurchasedTheseProductsAlsoBoughtBasket'], {
        cookieId,
        productIds,
        amount,
    });
};

export const personalRecommendationsForYouByCookieId = async ({
    cookieId,
    amount,
}: RaptorParams): Promise<string[]> => {
    return await getProducts(RaptorMethods['personalRecommendationsForYouByCookieId'], {
        cookieId,
        amount,
    });
};

export const getTrendingProducts = async ({
    cookieId,
    amount,
}: RaptorParams): Promise<string[]> => {
    return await getProducts(RaptorMethods['getTrendingProducts'], {
        cookieId,
        amount,
    });
};

export const getTrendingBrands = async ({ cookieId, amount }: RaptorParams): Promise<string[]> => {
    return await getProducts(RaptorMethods['getTrendingBrands'], {
        cookieId,
        amount,
    });
};

export const productsYouHaveFrequentlyPurchasedByUserId = async ({
    userId,
    cookieId,
    amount,
}: RaptorParams): Promise<string[]> => {
    return await getProducts(RaptorMethods['productsYouHaveFrequentlyPurchasedByUserId'], {
        cookieId,
        userId,
        amount,
    });
};

export const productsYouHaveRecentlySeenByUserId = async ({
    userId,
    cookieId,
    amount,
}: RaptorParams): Promise<string[]> => {
    return await getProducts(RaptorMethods['productsYouHaveRecentlySeenByUserId'], {
        cookieId,
        userId,
        amount,
    });
};

export const productsYouHaveRecentlySeenByCookieId = async ({
    cookieId,
    amount,
}: RaptorParams): Promise<string[]> => {
    return await getProducts(RaptorMethods['productsYouHaveRecentlySeenByCookieId'], {
        cookieId,
        amount,
    });
};

export const mostPurchasedProductsInAGivenBrand = async ({
    brand,
    cookieId,
    amount,
}: RaptorParams): Promise<string[]> => {
    return await getProducts(RaptorMethods['mostPurchasedProductsInAGivenBrand'], {
        cookieId,
        brand,
        amount,
    });
};

export const mostViewedProductsInAGivenBrand = async ({
    brand,
    cookieId,
    amount,
}: RaptorParams): Promise<string[]> => {
    return await getProducts(RaptorMethods['mostViewedProductsInAGivenBrand'], {
        cookieId,
        brand,
        amount,
    });
};

export const getPopularItemsInCategoryWeb = async ({
    userId,
    cookieId,
    category,
    amount,
}: RaptorParams): Promise<string[]> => {
    return await getProducts(RaptorMethods['getPopularItemsInCategoryWeb'], {
        cookieId,
        userId,
        category,
        amount,
    });
};

export const getPopularItemsInBrandWeb = async ({
    userId,
    cookieId,
    brand,
    amount,
}: RaptorParams): Promise<string[]> => {
    return await getProducts(RaptorMethods['getPopularItemsInBrandWeb'], {
        cookieId,
        userId,
        brand,
        amount,
    });
};

export const getUserItemRecommendationsWeb = async ({
    userId,
    cookieId,
    amount,
}: RaptorParams): Promise<string[]> => {
    return await getProducts(RaptorMethods['getUserItemRecommendationsWeb'], {
        userId,
        cookieId,
        amount,
    });
};

export const getMerchandisingItemsWeb = async ({
    userId,
    cookieId,
    merchandisingCampaignId,
}: RaptorParams): Promise<string[]> => {
    return await getProducts(RaptorMethods['getMerchandisingItemsWeb'], {
        userId,
        cookieId,
        merchandisingCampaignId,
    });
};

export const getPopularItemsWeb = async ({ cookieId, amount }: RaptorParams): Promise<string[]> => {
    return await getProducts(RaptorMethods['getPopularItemsWeb'], {
        cookieId,
        amount,
    });
};

export const handlers = {
    mostPurchasedProducts,
    mostPurchasedProductsInAGivenCategory,
    similarProductsOtherCustomersHaveAlsoPurchased,
    relatedProductOtherCustomersHaveAlsoPurchased,
    mostVisitedProducts,
    mostViewedProductsInAGivenCategory,
    customersWhoPurchasedTheseProductsAlsoBoughtBasket,
    personalRecommendationsForYouByCookieId,
    getTrendingProducts,
    getTrendingBrands,
    productsYouHaveFrequentlyPurchasedByUserId,
    productsYouHaveRecentlySeenByUserId,
    productsYouHaveRecentlySeenByCookieId,
    mostPurchasedProductsInAGivenBrand,
    mostViewedProductsInAGivenBrand,
    getPopularItemsInCategoryWeb,
    getPopularItemsInBrandWeb,
    getUserItemRecommendationsWeb,
    getMerchandisingItemsWeb,
    getPopularItemsWeb,
};
