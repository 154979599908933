import { RaptorDropdownTypes } from '~/lib';

export enum IncludeRelatedSimilar {
    Yes = 'Yes',
    No = 'No',
    Both = 'Both',
}

export enum RaptorMethods {
    mostPurchasedProducts = 'TopSelling',
    mostPurchasedProductsInAGivenBrand = 'TopSellingProductsForBrand',
    mostViewedProductsInAGivenBrand = 'TopViewedProductsForBrand',
    mostVisitedProducts = 'TopVisitedProducts',
    getTrendingProducts = 'Trending',
    getTrendingBrands = 'FromTrendingBrands',
    productsYouHaveRecentlySeenByCookieId = 'GetUserItemHistoryWeb',
    productsYouHaveRecentlySeenByUserId = 'MemberLastViewedProducts',
    productsYouHaveFrequentlyPurchasedByUserId = 'UserFrequentlyPurchasedProducts',
    personalRecommendationsForYouByCookieId = 'PersonalRecommendations',
    customersWhoPurchasedTheseProductsAlsoBoughtBasket = 'RelatedProductsOthersBoughtForBasket',
    mostPurchasedProductsInAGivenCategory = 'TopSellingProductsInCategory',
    mostViewedProductsInAGivenCategory = 'TopViewedProductsInCategory',
    relatedProductOtherCustomersHaveAlsoPurchased = 'RelatedProductsOthersBought',
    similarProductsOtherCustomersHaveAlsoPurchased = 'SimilarProductsOthersBought', //similar products that others have seen.
    getPopularItemsInCategoryWeb = 'GetPopularItemsInCategoryWeb',
    getPopularItemsInBrandWeb = 'GetPopularItemsInBrandWeb',
    getUserItemRecommendationsWeb = 'GetUserItemRecommendationsWeb',
    getMerchandisingItemsWeb = 'GetMerchandisingItemsWeb',
    getPopularItemsWeb = 'GetPopularItemsWeb',
}

export type RaptorParams = Partial<{
    category: string;
    cookieId: string;
    amount: number;
    productId: string;
    productIds: string[];
    relatedProducts: any;
    userId: string;
    includeSimilarProducts: IncludeRelatedSimilar;
    includeRelatedProducts: IncludeRelatedSimilar;
    brand: string;
    styleIds: string[];
    merchandisingCampaignId: string;
}>;
export interface IRaptor {
    config: RaptorDropdownTypes;
    param?: string;
    productId?: string;
    productBrand?: string;
    productCategory?: string;
    preventAutoFetch?: boolean;
    merchandisingCampaignId?: string;
}

export const endpoints: Partial<Record<RaptorMethods, string>> = {
    [RaptorMethods.mostPurchasedProductsInAGivenCategory]: 'TopSellingProductsInCategory',
    [RaptorMethods.personalRecommendationsForYouByCookieId]: 'PersonalRecommendations',
};
